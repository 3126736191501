<ngrx-busy>
  <ng-container *ngIf="subscription$ | async as subscription">
    <button mat-mini-fab
            color="primary"
            (click)="open()"
            matTooltip="Show subscriptions"
            class="subscription-icon">
      <mat-icon>card_membership</mat-icon>
    </button>

    <span [ngSwitch]="!!subscription.price ? subscription.price.interval : null"
          class="period">
      <ng-container *ngSwitchCase="'day'">Daily</ng-container>
      <ng-container *ngSwitchCase="'month'">Monthly</ng-container>
      <ng-container *ngSwitchCase="'week'">Weekly</ng-container>
      <ng-container *ngSwitchCase="'year'">Yearly</ng-container>
      <ng-container *ngSwitchCase="null">No</ng-container>
      subscription
    </span>

    <div class="progressbar">
      <div class="progress"
           [ngStyle]="{'width.%': completion(subscription)}"
           [ngClass]="{'critical': !!subscription.ended_at || completion(subscription) > 80, 'warn': completion(subscription) > 50}"></div>
    </div>

    <div class="desc">
      <span class="status">{{subscription.status || 'Not activated'}}</span>

      <span class="end">
        <ng-container [ngTemplateOutlet]="subscription.ended_at ? expired : subscription.cancel_at ? cancels : subscription.start_date ? active : no"></ng-container>
        <ng-template #expired>Expired on {{subscription.ended_at | moment:'ll'}}</ng-template>
        <ng-template #cancels>Cancels {{subscription.current_period_end | moment:'ll'}}</ng-template>
        <ng-template #active>Next invoice on {{subscription.current_period_end | moment:'ll'}}</ng-template>
        <ng-template #no></ng-template>
      </span>
    </div>
  </ng-container>
</ngrx-busy>
