<ngrx-busy>
  <mat-dialog-content>
    <h2 class="mat-h2">History</h2>

    <mat-selection-list #subs
                        [multiple]="false"
                        (selectionChange)="subscription_selection_change(subs.selectedOptions.selected[0]?.value)">
      <mat-list-option *ngFor="let sub of subscriptions"
                       [value]="sub">
        <div mat-line>{{sub.price.name}}
          <span *ngIf="sub.status !== 'trialing'"
                class="status-type"><span>{{sub.status}}</span></span>
          <span *ngIf="sub.status === 'trialing'"
                class="status-type">Trial ends <span>{{sub.trial_end | utc | moment:'medium'}}</span></span>
        </div>
        <div mat-line>Billing ${{formatted(sub.price.price)}} / {{sub.price.interval}}</div>
      </mat-list-option>
    </mat-selection-list>

    <div *ngIf="subscriptionInvoiceDataSource.data.length">
      <button *ngIf="selectedSubscription && selectedSubscription.status === 'active'"
              mat-flat-button
              color="warn"
              (click)="cancel_subscription(selectedSubscription)">
        <mat-icon>cancel</mat-icon>
        Cancel
      </button>

      <table mat-table
             [dataSource]="subscriptionInvoiceDataSource"
             multiTemplateDataRows>
        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell
              *matHeaderCellDef> AMOUNT
          </th>
          <td mat-cell
              *matCellDef="let element"> {{formatted(element.amount_paid)  }} {{element.currency}} {{element.status}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef> INVOICE NUMBER
          </th>
          <td mat-cell
              *matCellDef="let element"> {{element.number}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
          <th mat-header-cell
              *matHeaderCellDef> CREATED
          </th>
          <td mat-cell
              *matCellDef="let element"> {{element.created | utc | moment}} </td>
        </ng-container>


        <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            (click)="click_invoice(row)"
            *matRowDef="let row; columns: displayedColumns;"
            class="example-element-row"></tr>
      </table>
    </div>


    <div *ngIf="selectedInvoice && selectedInvoice.payments?.length>0">
      <h3 class="mat-h2">History</h3>
      <mat-list>
        <mat-list-item *ngFor="let payment of selectedInvoice.payments">
          <div mat-line>${{formatted(payment.amount)}}
            <span class="status-type">{{payment.status}}<span></span></span>
          </div>
          <div mat-line>Paid on {{payment.created | utc | moment:'medium'}}  {{payment.description}} </div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-dialog-content>
</ngrx-busy>
