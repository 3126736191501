import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SharedModule} from 'shared';
import {CustomersComponent} from './customers.component';
import {CustomersDashboardComponent} from './customer-dashboard/customer-dashboard.component';
import {CustomerCardComponent} from './customer-card/customer-card.component';
import {PetCardComponent} from './pet-card/pet-card.component';
import {PetDetailComponent} from './pet-detail/pet-detail.component';
import {TracksComponent} from './tracks/tracks.component';
import {TrackDetailComponent} from './track-detail/track-detail.component';
import {TraceDetailComponent} from './trace-detail/trace-detail.component';
import {CustomerDetailsComponent} from './customer-details/customer-details.component';
import {RequestsModule} from 'app/requests/requests.module';
import {SafeZoneCardComponent} from './safe-zone-card/safe-zone-card.component';
import {FeedCardComponent} from './feed-card/feed-card.component';
import {SessionCardComponent} from './session-card/session-card.component';
import {CollarCardComponent} from './device-card/collar-card.component';
import {SubscriptionCardComponent} from './subscription-card/subscription-card.component';
import {SubscriptionsComponent} from './subscription-card/subscriptions.component';
import {CollarBatteriesComponent} from "./device-card/collar-batteries.component";
import {CustomerStore} from './customer.store';
import {
  AchievementsPublicClient,
  API_BASE_URL_PUBLIC,
  TimelinePublicClient,
  UserPublicClient,
  BillingPublicClient
} from './public-clients';
import {environment} from '../../environments/environment';
import {ActivityCardComponent} from "./activity-card/activity-card.component";
import {FlashlightsComponent} from "./flashlights/flashlights.component";

@NgModule({
  declarations: [
    CustomersComponent,
    CustomersDashboardComponent,
    CustomerCardComponent,
    PetCardComponent,
    PetDetailComponent,
    TracksComponent,
    SafeZoneCardComponent,
    TrackDetailComponent,
    TraceDetailComponent,
    FlashlightsComponent,
    CustomerDetailsComponent,
    FeedCardComponent,
    SessionCardComponent,
    CollarCardComponent,
    SubscriptionCardComponent,
    ActivityCardComponent,
    SubscriptionsComponent,
    CollarBatteriesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatProgressSpinnerModule,
    RequestsModule,
    RouterModule.forChild([
      {
        path: '',
        component: CustomersComponent
      },
      {
        path: ':id',
        component: CustomersDashboardComponent,
        children: [
          {
            path: '',
            component: CustomerDetailsComponent,
            pathMatch: 'full'
          },
          {
            path: 'pets/:id',
            component: PetDetailComponent,
            children: [
              // {
              //   path: '',
              //   component: TracksComponent
              // },
              {
                path: 'tracks/:id',
                component: TrackDetailComponent
              },
              {
                path: 'traces/:trace_id',
                component: TraceDetailComponent
              },
              {
                path: 'flashlights/:collar_id',
                component: FlashlightsComponent
              }
            ]
          }
        ]
      }
    ])
  ],
  providers: [
    CustomerStore,
    TimelinePublicClient,
    UserPublicClient,
    AchievementsPublicClient,
    BillingPublicClient,
    {provide: API_BASE_URL_PUBLIC, useFactory: () => environment.mobileApiUrl}
  ]
})
export class CustomersModule {
}
